<template>
  <div class="attendance-rate-container">
    <div>
      <el-form ref="form"
                :model="form"
                label-position="right"
                label-width="120px"
                :rules="rules"
                :inline="true"
                style="margin-top:30px;">
            <el-form-item label="企业名称:"
                      prop="companyName"
                      >
              <el-input v-model="form.companyName"
                        placeholder="请输入企业名称"
                        />
            </el-form-item>
            <el-form-item label="项目名称:"
                      prop="projectName"
                      >
              <el-input v-model="form.projectName"
                        placeholder="请输入项目名称"
                        />
            </el-form-item>
            <el-form-item label="统计周期:"
                      prop="cycle"
                      >
              <el-date-picker
                v-model="form.cycle"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
        <el-divider></el-divider>
        <scg-form-btn
          @search="handleSearch"
          @reset="handleReset('form')">
          <el-button type="normal" class="func-btn" icon="el-icon-download" @click="handleExport">导出</el-button>
        </scg-form-btn>
      </el-form>
    </div>
    <div class="result">
      <el-table fixed
              ref="filterTable"
              :data="tableData"
              border
              >
        <el-table-column type='index'
                          width="55"
                          label="序号"
                          align="center"
                          >
        </el-table-column>
        <el-table-column label="项目名称" prop="projectName" align="center" />
        <el-table-column label="所属公司" prop="companyAbbreviation" align="center" />
        <el-table-column label="进场人数" prop="entryPeopleNum" align="center" />
        <el-table-column label="出勤人数" prop="attendancePeopleNum" align="center" />
        <el-table-column label="考勤率（%）" prop="rate" align="center">
        </el-table-column>
      </el-table>
      <scg-pagination :pagination="pagination" @currentPageChange="getCurrentPage" @handleSizeChange="getCurrentPageSize"></scg-pagination>
    </div>
    <scg-project-dialog
      :showDialog="showProjectDialog"
      @close="hiddenDialog" />
  </div>
</template>
<script>
import ScgPagination from '@/components/Scg-pagination'
import ScgFormBtn from '@/components/Scg-form-btn'
import ScgProjectDialog from '@/components/Scg-project-dialog'
export default {
  name: 'Attendance-rate',
  components: {
    ScgPagination,
    ScgFormBtn,
    ScgProjectDialog
  },
  data(){
    return {
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      form: {
        cycle: [], // 统计周期
        companyId:'', // 当前用户公司ID
      },
      rules: {},
      genderList: [{
        label:'男',
        value:'1'
      },{
        label:'女',
        value:'2'
      }],
      workTypeList:[],
      teamList:[],
      showProjectDialog: false,
      checkMe: false,
      
    }
  },
  methods:{
    handleChecked(){
      console.log(this.form)
      this.form.companyId = this.checkMe ? localStorage.getItem('companyId') : ''
      this.getDataList()
    },
    /**
     * 导出
     */
    handleExport(){
      console.log('handleExport~')
    },

    /**
     * 重置
     */
    handleReset(formName){
      this.$refs[formName].resetFields()
      this.pagination.currentPage = 1
      this.getDataList()
    },

    /**
     * 查询
     */
    handleSearch(){
      console.log(this.form)
      this.pagination.currentPage = 1
      this.getDataList()
    },

    /**
     * 每页显示条目个数切换
     * @param val
     */
    getCurrentPageSize(val){
      this.pagination.currentPage = 1
      this.pagination.pageSize = val
      this.getCurrentPage()
    },
    
    /**
     * 页码切换，触发重载数据
     * @param val
     */
    getCurrentPage(val) {
      this.pagination.currentPage = val
      this.initData()
    },

    /**
     * 获取列表信息
     */
    getDataList(){
      this.tableData=[{
         companyName:'劳务单位1',
        companyAbbreviation: '劳务单位1',
        createTime:'2020-12-25',
        projectName:'项目1',
        partitionName:'施工区',
        entryPeopleNum:300,
        attendancePeopleNum:400,
        rate:'80.0%'
      },{
         companyName:'劳务单位1',
        companyAbbreviation: '劳务单位1',
        createTime:'2020-12-25',
        projectName:'项目1',
        partitionName:'施工区',
        entryPeopleNum:300,
        attendancePeopleNum:280,
        rate:'80.0%'
      },{
         companyName:'劳务单位1',
        companyAbbreviation: '劳务单位1',
        createTime:'2020-12-25',
        projectName:'项目1',
        partitionName:'施工区',
        entryPeopleNum:300,
        attendancePeopleNum:400,
        rate:'80.0%'
      },{
         companyName:'劳务单位1',
        companyAbbreviation: '劳务单位1',
        createTime:'2020-12-25',
        projectName:'项目1',
        partitionName:'施工区',
        entryPeopleNum:300,
        attendancePeopleNum:400,
        rate:'80.0%'
      },{
         companyName:'劳务单位1',
        companyAbbreviation: '劳务单位1',
        createTime:'2020-12-25',
        projectName:'项目1',
        partitionName:'施工区',
        entryPeopleNum:300,
        attendancePeopleNum:400,
        rate:'80.0%'
      }]
    },

    /**
     * 初始化信息
     */
    initData(){
      this.getDataList()
    }
  },
  mounted(){
    this.initData()
  }
}
</script>
<style lang="stylus" scoped>
.attendance-rate-container
  background-color #fff
  margin 24px
  padding 10px
  
  .result
    border-top solid 1px #F0F2F5
    .list
      width 277px
      margin-top 37px
      
    .table
      flex 1
      padding-top 24px
      padding-left 24px
      border-left solid 1px #F0F2F5
  .custom-tree-node
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    .info
      font-size 12px
      .set-top
        color #377CED
        margin-left 12px
      .move-up
        color #7CB305
      .move-down
        color #FA8C16
</style>
<style lang="stylus">
// .labor-container
  
</style>