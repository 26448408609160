<template>
  <div class="wage-container">
    <div>
      <el-form ref="form"
                :model="form"
                label-position="right"
                label-width="120px"
                :rules="rules"
                :inline="true"
                style="margin-top:30px;">
        <el-form-item label="企业名称:"
                  prop="companyName"
                  >
          <el-input v-model="form.companyName"
                    placeholder="请输入企业名称"
                    />
        </el-form-item>
        <el-form-item label="项目名称:"
                  prop="projectName"
                  >
          <el-input v-model="form.projectName"
                    placeholder="请输入项目名称"
                    />
        </el-form-item>
        <el-form-item label="统计周期:"
                  prop="cycle"
                  >
          <el-date-picker
            v-model="form.cycle"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button type="normal" @click="handleReset">重置</el-button>
        <el-divider></el-divider>
        <scg-form-btn
          :showFormBasicBtn="false"
          >
          <el-button type="normal" class="func-btn" icon="el-icon-download" @click="handleExport">导出</el-button>
          <div slot="right">
            <el-checkbox v-model="checkMe" @change="handleChecked">只看本单位</el-checkbox>
          </div>
        </scg-form-btn>
      </el-form>
    </div>
    <div class="result">
      <div id="main" style="width: 100%;height:300px;"></div>
      <el-table fixed
              ref="filterTable"
              :data="tableData"
              border
              >
        <el-table-column type='index'
                          width="55"
                          label="序号"
                          align="center"
                          >
        </el-table-column>
        <el-table-column label="项目名称" prop="projectName" align="center" />
        <el-table-column label="所属公司" prop="companyAbbreviation" align="center" />
        <el-table-column label="进场人数" prop="entryPeopleNum" align="center" />
        <el-table-column label="出勤人数" prop="attendancePeopleNum" align="center" />
        <el-table-column label="考勤率（%）" prop="rate" align="center">
        </el-table-column>
      </el-table>
      <scg-pagination :pagination="pagination" @currentPageChange="getCurrentPage" @handleSizeChange="getCurrentPageSize"></scg-pagination>
    </div>
  </div>
</template>
<script>
import ScgPagination from '@/components/Scg-pagination'
import ScgFormBtn from '@/components/Scg-form-btn'
export default {
  name: 'Wage',
  components: {
    ScgPagination,
    ScgFormBtn
  },
  data(){
    return {
      myChart:null,
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      form: {
        cycle: [], // 统计周期
        companyId:'', // 当前用户公司ID
      },
      rules: {},
      genderList: [{
        label:'男',
        value:'1'
      },{
        label:'女',
        value:'2'
      }],
      workTypeList:[],
      teamList:[],
      showProjectDialog: false,
      checkMe: false,
      
    }
  },
  methods:{
    handleChecked(){
      console.log(this.form)
      this.form.companyId = this.checkMe ? localStorage.getItem('companyId') : ''
      this.getDataList()
    },
    /**
     * 导出
     */
    handleExport(){
      console.log('handleExport~')
    },

    /**
     * 重置
     */
    handleReset(formName){
      this.$refs[formName].resetFields()
      this.pagination.currentPage = 1
      this.getDataList()
    },

    /**
     * 查询
     */
    handleSearch(){
      console.log(this.form)
      this.pagination.currentPage = 1
      this.getDataList()
    },

    /**
     * 每页显示条目个数切换
     * @param val
     */
    getCurrentPageSize(val){
      this.pagination.currentPage = 1
      this.pagination.pageSize = val
      this.getCurrentPage()
    },
    
    /**
     * 页码切换，触发重载数据
     * @param val
     */
    getCurrentPage(val) {
      this.pagination.currentPage = val
      this.initData()
    },

    /**
     * 获取列表信息
     */
    getDataList(){
      this.tableData=[{
         companyName:'劳务单位1',
        companyAbbreviation: '劳务单位1',
        createTime:'2020-12-25',
        projectName:'项目1',
        partitionName:'施工区',
        entryPeopleNum:300,
        attendancePeopleNum:400,
        rate:'80.0%'
      },{
         companyName:'劳务单位1',
        companyAbbreviation: '劳务单位1',
        createTime:'2020-12-25',
        projectName:'项目1',
        partitionName:'施工区',
        entryPeopleNum:300,
        attendancePeopleNum:280,
        rate:'80.0%'
      },{
         companyName:'劳务单位1',
        companyAbbreviation: '劳务单位1',
        createTime:'2020-12-25',
        projectName:'项目1',
        partitionName:'施工区',
        entryPeopleNum:300,
        attendancePeopleNum:400,
        rate:'80.0%'
      },{
         companyName:'劳务单位1',
        companyAbbreviation: '劳务单位1',
        createTime:'2020-12-25',
        projectName:'项目1',
        partitionName:'施工区',
        entryPeopleNum:300,
        attendancePeopleNum:400,
        rate:'80.0%'
      },{
         companyName:'劳务单位1',
        companyAbbreviation: '劳务单位1',
        createTime:'2020-12-25',
        projectName:'项目1',
        partitionName:'施工区',
        entryPeopleNum:300,
        attendancePeopleNum:400,
        rate:'80.0%'
      }]
    },

    /**
     * 初始化信息
     */
    initData(){
      this.getDataList()
      this.drawLine()
    },
    drawLine(){
      // 基于准备好的dom，初始化echarts实例
      var that = this;
        this.myChart = this.$echarts.init(document.getElementById('main'));
        //点击事件
        this.myChart.on('click', function(param) {
          console.log(param);
          console.log(param.name);
          console.log(param.value);
        });
        // 指定图表的配置项和数据
        var option = {
          title: {
              text: '项目工资发放情况'
          },
          tooltip: {},
          legend: {
              data:['工资发放人数','未发工资人数']
          },
          grid: {
            containLabel: true
          },
          xAxis: {
              data: ["一建","二建","三建","四建","五建","六建","七建","八建","九建","十建","十一建","十二建"],
          },
          yAxis: {},
          series: [{                               //新的一个柱子 注意不设stack
            name: '工资发放人数',       
            type: 'bar',
            stack: "预警",//折叠显示
            data: [360, 340, 336, 310, 310, 220, 205, 200, 136, 110, 110, 100],
            color:'#5b9bd5',
            label: {                 
                normal: {
                    show: true, //显示数值
                    position: 'inside',       //  位置设为top
                    formatter: '{c}',
                    textStyle: { color: '#000' } //设置数值颜色
                }
              },
          },{
              name: '未发工资人数',
              type: 'bar',
              stack: "预警",//折叠显示
              data: [80, 70, 66, 60, 60, 60, 65, 60, 66, 60, 60, 60],
              color:"#DFEAF5",
              label: {                 
                normal: {
                    show: true, //显示数值
                    position: 'inside',       //  位置设为top
                    formatter: '{c}',
                    textStyle: { color: '#000' } //设置数值颜色
                }
              },
          }]
        };

        // 使用刚指定的配置项和数据显示图表。
        that.myChart.setOption(option);
    }
  },
  mounted(){
    this.initData()
  }
}
</script>
<style lang="stylus" scoped>
.wage-container
  background-color #fff
  margin 24px
  padding 10px
  
  .result
    border-top solid 1px #F0F2F5
    .list
      width 277px
      margin-top 37px
      
    .table
      flex 1
      padding-top 24px
      padding-left 24px
      border-left solid 1px #F0F2F5
  .custom-tree-node
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    .info
      font-size 12px
      .set-top
        color #377CED
        margin-left 12px
      .move-up
        color #7CB305
      .move-down
        color #FA8C16
</style>
<style lang="stylus">
// .labor-container
  
</style>